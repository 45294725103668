
.circ {
  background-color: #f5d4b8;
  border-radius: 50%;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 180px;
}

.box-of-boxes {
  padding-bottom: 500px;
  padding-top: 50px;
  justify-content: center;
}

.dev-box img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.left {
  padding: 15px;
  display: flex;
  float: left;
  width: 50%;
  text-align: left;
}

.right {
  padding: 15px;
  display: flex;
  float: right;
  width: 50%;
  text-align: left;
}

h1 {
  text-decoration: underline;
}

.box1 {
  align-items: center;
}

.website {
  color: #f5d4b8;
  align-items: center;
  font-style: italics;
}

footer {
  align-items: center;
  font-style: italics;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #70878f85;
  color: rgb(46, 46, 46);
  text-align: center;
}