.image {
  overflow: hidden;
  background-size: cover;
  display: block;
}

.image2 {
  border-radius: 50%;
  width: 200px;

}

.img-container {
  display: inline;
  margin-left: auto;
  margin-right: auto;
  width: 200vw;
  max-width: 1200px;
  /* margin: 3rem auto; */
  display: grid;
  gap: 1rem;

}

@media screen and (min-width: 50px) {
  .img-container {
    grid-template-columns: repeat(4, 1fr);

  }
}