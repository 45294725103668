.nav-bar {
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: #70878f85;
}

.nav-bar a:link {
  color: rgb(51, 53, 56)!important;
} 

.nav-bar a:hover {
  color: rgb(138, 115, 94)!important;
}
